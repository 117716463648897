import React from 'react';
import styled from "styled-components"

const SectionWrapper = styled.section`
  display : flex;
  flex-direction : row;
  align-items : flex-start;
  justify-content : center;
`;

const SectionContent = styled.div`
  max-width : 1440px;
`;

const Section = ({children, wrapperStyle = {}, WrapperComponent = SectionWrapper, ...props}) => (
  <WrapperComponent style={wrapperStyle}>
    <SectionContent {...props}>
      {children}
    </SectionContent>
  </WrapperComponent>
);

export default Section;
export { SectionWrapper };
