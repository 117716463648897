import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"

import Section from '../components/section';

import { dimensions } from '../shared/styles';

import packageJson from '../../package.json';

/* ---------------------------------------------- */
/* ---------------------------------------------- */

const MainSection = styled(Section).attrs(props => ({
  wrapperStyle : {
    height : '100%'
  }
}))`
  height : 100%;
  padding-top : ${dimensions.headerHeight};
  margin-bottom : 15rem;
  position : relative;
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content : flex-start;

  & p {font-size : 3rem;}
`;


const NotFoundPage = () => (
  <Layout>
    <MainSection>
      <p>{packageJson.version}</p>
      <p style={{opacity : 0.01}}>
        <a href={require('../data/references/agami.mp4')} target="_blank"></a>
      </p>
    </MainSection>
  </Layout>
)

export default NotFoundPage
